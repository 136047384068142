<template>
	<div class="signContent">
		<div class="sign">
			<img src="../assets/img/jt.png" alt="" @click="$router.push('/')">
			<p>注册</p>
		</div>
		<div class="form">
			<div>
				<p>您的称呼：</p>
				<input type="text" v-model="form.name" placeholder="请告知我们如何称呼您">
			</div>
			<div>
				<p>注册公司：</p>
				<input type="text" v-model="form.company_name" placeholder="请填写您的公司全称">
			</div>
			<div>
				<p>联系电话：</p>
				<input type="text" v-model="form.telephone" placeholder="请填写您的联系人电话">
			</div>
			<div>
				<p>注册电话：</p>
				<input type="text" v-model="form.mobile" placeholder="请填写您的注册电话">
			</div>
			<div>
				<p>验证码：</p>
				<span><input type="text" v-model="form.code" placeholder="请输入验证码"><b v-if="timerTf === false"
						@click="codeBtn">获取验证码</b>
					<b class="b" v-else>{{ codeNum }}秒后重新获取</b>
				</span>
			</div>
			<div>
				<p>设置密码：</p>
				<div class="paw">
					<input type="password" id="pwd" v-model="form.password" placeholder="请设置登录密码" @input="passwordBtn">
					<img src="../assets/img/yanjing_yincang_o.png" alt="" class="passIcon" @click="incoBtn"
						:style="{ display: state ? 'block' : 'none' }" v-if="flag === 0">
					<img src="../assets/img/yanjing-1.png" alt="" class="passIcon" @click="incoBtn"
						:style="{ display: state ? 'block' : 'none' }" v-else>
				</div>
			</div>
			<div>
				<p>需求描述：</p>
				<!-- <input type="textarea" placeholder="可简要描述您期望实现的效果" > -->
				<textarea name="" id="" v-model="form.demands" placeholder="可简要描述您期望实现的效果" class="tac"></textarea>
			</div>
			<button class="btn" @click="onSubmit">提交</button>
		</div>
	</div>
</template>

<script>
	import '@/utils/rems'
	import {
		ref,
		reactive
	} from 'vue'
	import {
		ElMessage
	} from 'element-plus'
	import company from '@/api/company'
	import user from '@/api/user'
	import { useRouter } from "vue-router"
	
	export default {
		setup() {
			const router = useRouter()
			//注册
			const form = reactive({
				name: '',
				company_name: '',
				mobile: '',
				code: '',
				password: '',
				demands: '',
				telephone: ''
			})
			//提交

			const onSubmit = () => {
				company.Register(form).then(() => {
					ElMessage.success('注册成功！')
					// dialogVisible.value = false
					router.push('/');
				}).catch(err => {
					ElMessage.error(err)
				});
			}

			const codeText = ref('获取验证码')
			const codeNum = ref(60)
			var codeTime = null
			const timerTf = ref(false)
			//获取验证码
			const codeBtn = () => {
				if (form.mobile === "") {
					ElMessage.error('请输入注册电话')
					return
				} else {
					user.VerificCode(form.mobile,1,"").then(() => {
						codeTime = setInterval(() => {
							timerTf.value = true
							codeNum.value--
							if (codeNum.value < 0) {
								timerTf.value = false
								clearInterval(codeTime)
								codeNum.value = 60
							}
						}, 1000)
					}).catch((err) => {
						ElMessage.error(err)
					});
				}
			}

			const state = ref(false)
			const passwordBtn = () => {
				if (form.password === '') {
					state.value = false
				} else {
					state.value = true
				}
			}
			const flag = ref(0)
			const incoBtn = () => {
				var pwd = document.getElementById('pwd');
				if (flag.value == 0) {
					pwd.type = 'text';
					flag.value = 1; //赋值操作
				} else {
					pwd.type = 'password';
					flag.value = 0;

				}
			}
			return {
				onSubmit,
				form,
				codeText,
				codeNum,
				codeTime,
				timerTf,
				codeBtn,
				state,
				passwordBtn,
				flag,
				router,
				incoBtn
			}
		}
	};
</script>

<style lang="scss" scoped>
	.signContent {
		.sign {

			padding-left: 5rem;
			width: 100%;
			height: 50px;

			display: flex;
			align-items: center;

			img {
				width: 7rem;
				height: 6rem;
			}

			p {
				margin-left: 3rem;
				font-size: 5rem;
				color: #33333B;
				font-weight: 900;

			}
		}

		.form {
			padding: 0 12rem;
			margin-top: 13rem;

			div {
				.paw {
					display: flex;
					width: 100%;

					img {
						width: 8rem;
						height: 8rem;
					}
				}

				p {
					font-size: 5rem;
					margin-bottom: 6rem;
					color: #D5D5DC;
				}

				input {
					width: 100%;
					border: 0;
					height: 10rem;
					outline: none;
					border-bottom: 1px solid #E2E2EA;
					margin-bottom: 7rem;

				}

				input::-webkit-input-placeholder {
					font-size: 5rem;

				}

				span {
					width: 100%;
					height: 10rem;
					margin-bottom: 7rem;
					display: flex;
					justify-content: space-between;

					input {
						width: 50%;
					}

					border-bottom: 1px solid #E2E2EA;

					b {
						font-weight: normal;
						font-size: 5rem;
						color: #1E65D6;
					}

					.b {
						font-weight: normal;
						font-size: 5rem;
						color: #E2E2EA;
					}
				}

				.tac {
					width: 100%;
					height: 33rem;
					border: 1px solid #E2E2EA;
				}

				.tac {
					padding: 5rem 0 0 5rem;
				}

				// .tac::-webkit-input-placeholder { 
				//   padding: 5rem 0 0 5rem;

				// }
			}

			.btn {
				width: 100%;
				height: 15rem;
				background-color: #1E65D6;
				color: #fff;
				font-size: 4.7rem;
				border: 0;
				outline: none;
				border-radius: 1rem;
				margin: 5rem 0 20rem 0;
			}
		}
	}
</style>
